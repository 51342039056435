import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {
  public static Events = {
    SignInSuccess: 'sign-in-success'
  };

  private static eventEmitters: EventEmitter<any>[] = [];

  constructor() { }

  public static getEventEmitter(eventName: any) {
    this.eventEmitters[eventName] = this.eventEmitters[eventName] || new EventEmitter();
    return this.eventEmitters[eventName];
  }

}
