import { Injectable } from '@angular/core';
import { TezosToolkit, MichelsonMap } from "@taquito/taquito";
import { BeaconWallet } from "@taquito/beacon-wallet";
import { BeaconSetting, AppSetting } from '@shared/app-setting';
import { EventEmitterService } from './event-emitter.service';
import { environment } from '@environment';
import { NetworkType } from "@airgap/beacon-sdk";
import { APIService } from './api.service';
import { create } from 'ipfs-http-client';


const BeaconAccount = 'beacon-account';

@Injectable({
  providedIn: 'root'
})
export class AutonomyBeaconService {
  private Tezos: TezosToolkit | undefined;
  private wallet: BeaconWallet | undefined;
  private networkType: NetworkType = NetworkType.MAINNET;

  constructor(
    private apiService: APIService
  ) {
    this.init();
  }

  public async connectWallet() {
    try {
      console.log("Requesting permissions...");
      if (this.wallet) {
        let permissions: any;
        if (environment.production) {
          permissions = await this.wallet.client.requestPermissions();
        } else {
          permissions = await this.wallet.client.requestPermissions({
            network: {
              type: this.networkType,
              rpcUrl: environment.tezosToolkit,
            }
          });
        }
        
        EventEmitterService.getEventEmitter(EventEmitterService.Events.SignInSuccess).emit(permissions.address);
      }
    } catch (error) {
      console.log("Got error:", error);
    }
  }

  public async disconnectWallet() {
    try {
      if (this.wallet) {
        const activeAccount = await this.wallet.client.getActiveAccount();
        if (activeAccount) {
          this.wallet.clearActiveAccount();
          this.clearData();
        } else {
          this.clearData();
        }
      }
    } catch (error) {
      console.log('Got error:', error);
    }
  }

  public async mintArtwork(metadata: string, editionSize: number) {
    if (this.wallet && this.Tezos) {
      const contract = await this.Tezos.wallet.at(environment.contractAddress);

      let m = MichelsonMap.fromLiteral({
        "": Uint8Array.from(Buffer.from(metadata))
      })
      await contract.methods.mint(editionSize, m).send();
    }
  }

  public uploadFile(formData: FormData) {
    return this.apiService.post('/upload_artwork', formData, {});
  }

  public createIPFSFile(metadata: string) {
    const auth = 'Basic ' + Buffer.from(environment.infuraProjectId + ':' + environment.infuraProjectSecret).toString('base64');

    const client = create({
      host: 'ipfs.infura.io',
      port: 5001,
      protocol: 'https',
      headers: {
        authorization: auth
      }
    });

    return client.add(metadata);
  }

  private init() {
    this.Tezos = new TezosToolkit(environment.tezosToolkit);
    this.wallet = new BeaconWallet({ name: AppSetting.AppName });
    this.networkType = environment.production ? NetworkType.MAINNET : NetworkType.GRANADANET;

    this.Tezos.setWalletProvider(this.wallet);
  }

  private clearData() {
    localStorage.removeItem(BeaconSetting.Accounts);
    localStorage.removeItem(BeaconSetting.SdkVersion);
    localStorage.removeItem(BeaconSetting.MatrixSelectedNode);
    localStorage.removeItem(BeaconSetting.CommunicationPeersDapp);
    localStorage.removeItem(BeaconSetting.SdkSecretSeed);
    localStorage.removeItem(BeaconSetting.SdkMatrixPreservedState);
    localStorage.removeItem(BeaconSetting.ActivePeer);
    localStorage.removeItem(BeaconSetting.ActiveAccount);
  }
}
