import { Injectable } from '@angular/core';
import { Me, User } from '@core/models/user.model';
import { BeaconSetting } from '@shared/app-setting';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private me: Me | undefined;

  constructor() {
    this.getLocalUser();
  }

  public getMe = () => this.me;

  public updateMe(address: string) {
    let user: User = {
      address: address
    }
    this.me = Object.assign(this.me, user);
  }

  public getReloadUser() {
    this.getLocalUser();
    return this.me;
  }

  private getLocalUser() {
    const beaconAccountsObject = localStorage.getItem(BeaconSetting.Accounts);
    if (beaconAccountsObject) {
      const beaconAccounts = JSON.parse(beaconAccountsObject);
      if (beaconAccounts && beaconAccounts.length) {
        const me: Me = {
          address: beaconAccounts[0].address
        }
        this.me = me;
      }
    }
  }
}
