import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/temple-wallet/temple-wallet.module').then(m => m.TempleWalletModule) 
  },
  {
    path: 'temple-wallet',
    loadChildren: () => import('./modules/temple-wallet/temple-wallet.module').then(m => m.TempleWalletModule) 
  },
  {
    path: 'metamask-wallet',
    loadChildren: () => import('./modules/metamask-wallet/metamask-wallet.module').then(m => m.MetamaskWalletModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
