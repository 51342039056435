import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'aliasFormat'
})
export class AliasFormatPipe implements PipeTransform {

  transform(value: string): string {
    value = value ? value.trim() : '';
    if (value && value.length > 20 && value.split(' ').length < 2) {
      return `[${value.slice(0, 4)}....${value.slice(value.length - 4, value.length)}]`.toLocaleUpperCase();
    } else {
      return value;
    }
  }

}
