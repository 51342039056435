import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'autonomy-io';

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && (outlet.activatedRouteData.animation);
  }

  public onActivate(outlet: RouterOutlet) {
    window.scroll(0, 0);
  }
}
