export const BeaconSetting = {
  SdkVersion: 'beacon:sdk_version',
  MatrixSelectedNode: 'beacon:matrix-selected-node',
  AppMetadataList: 'beacon:app-metadata-list',
  MatrixPeerRooms: 'beacon:matrix-peer-rooms',
  CommunicationPeersDapp: 'beacon:communication-peers-dapp',
  SdkSecretSeed: 'beacon:sdk-secret-seed',
  SdkMatrixPreservedState: 'beacon:sdk-matrix-preserved-state',
  ActivePeer: 'beacon:active-peer',
  ActiveAccount: 'beacon:active-account',
  Accounts: 'beacon:accounts',
}

export const AppSetting = {
  AppName: 'Autonomy Popup'
}