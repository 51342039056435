import environmentJson from '../environments/environment.json';

export const environment = {
  production: environmentJson.production,
  apiPrefix: environmentJson.apiPrefix,
  tezosToolkit: environmentJson.tezosToolkit,
  contractAddress: environmentJson.contractAddress,
  infuraProjectId: environmentJson.infuraProjectId,
  infuraProjectSecret: environmentJson.infuraProjectSecret,
  websocketPrefix: environmentJson.websocketPrefix
}
