import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@environment';
// import * as errors from '@core/errors';

@Injectable({
  providedIn: 'root'
})
export class APIService {
  constructor(protected http: HttpClient) {}

  public call(method: string, url: string, params?: any, options?: any, withPaging?: boolean): Observable<any> {
    url = url.startsWith('http') ? url : `${environment.apiPrefix}${url}`;
    let request: Observable<any>;

    return Observable.create((observer: any) => {
      switch (method) {
        case 'post':
          request = this.http.post(url, params, options);
          break;
        case 'put':
          request = this.http.put(url, params, options);
          break;
        case 'patch':
          request = this.http.patch(url, params, options);
          break;
        case 'get':
          request = this.http.get(url, options);
          break;
        case 'head':
          request = this.http.head(url);
          break;
        case 'delete':
          request = this.http.delete(url, options);
          break;
        default:
          break;
      }

      request.subscribe(
        (data: any) => {
          if (withPaging) {
            observer.next(data &&
              {
                result: (data.result || data.results),
                paging: data.paging
              });
            observer.complete();
          } else {
            observer.next(data);
            observer.complete();
          }
        },
        (err: HttpErrorResponse) => observer.error(err)
      );
    });
  }

  public get = (url: string, options?: any, withPaging?: boolean): Observable<any> => this.call('get', url, null, options, withPaging);
  public post = (url: string, body?: any, options?: any): Observable<any> => this.call('post', url, body, options);
  public put = (url: string, body?: any, options?: any): Observable<any> => this.call('put', url, body, options);
  public patch = (url: string, body?: any, options?: any): Observable<any> => this.call('patch', url, body, options);
  public head = (url: string): Observable<any> => this.call('head', url);
  public delete = (url: string, options?: any): Observable<any> => this.call('delete', url, null, options);
}
