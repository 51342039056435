<header *ngIf="!isArtist">
  <a class="brand" [routerLink]="['/']">
    <img src="/assets/images/au.svg">
    <h2>Autonomy<span> Pop-Up</span></h2>
  </a>
  <div *ngIf="me" class="account">
    <div class="user">
      <p class="greeting">{{me.address | aliasFormat}}</p>
      <!--<p class="balance">1,100.00 <span>USDC</span><img src="img/eye-open.svg"></p>-->
    </div>
    <ul class="dropdown">
      <li><a [routerLink]="['mint']">Mint</a></li>
      <li><a [routerLink]="['preview']">Preview</a></li>
      <li><a (click)="signOut()">Sign Out</a></li>
    </ul>
    <div class="nav-icon">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</header>


<!--For collect-artwork component-->
<header *ngIf="isArtist">
  <a class="brand" [routerLink]="['/']">
    <h2>KC's<span> Pop-Up</span></h2>
  </a>
  <div *ngIf="me" class="account">
    <div class="user">
      <p class="greeting">{{me.address | aliasFormat}}</p>
      <!--<p class="balance">1,100.00 <span>USDC</span><img src="img/eye-open.svg"></p>-->
    </div>
    <ul class="dropdown">
      <li><a [routerLink]="['mint']">Mint</a></li>
      <li><a [routerLink]="['preview']">Preview</a></li>
      <li><a (click)="signOut()">Sign Out</a></li>
    </ul>
    <div class="nav-icon">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</header>