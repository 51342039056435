import { Component, OnInit } from '@angular/core';
import { Me } from '@core/models/user.model';
import { UserService } from '@core/services/user.service';
import { AutonomyBeaconService } from '@core/services/autonomy-beacon.service';
import { EventEmitterService } from '@core/services/event-emitter.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {
  public me: Me | undefined;
  public isArtist: boolean = false;

  constructor(
    private userService: UserService,
    private autonomyBeaconService: AutonomyBeaconService
  ) {
    this.me = this.userService.getMe();
    EventEmitterService.getEventEmitter(EventEmitterService.Events.SignInSuccess).subscribe((data) => {
      if (data) {
        this.me = this.userService.getReloadUser();
      }
    });
  }

  ngOnInit(): void {
  }

  public signOut() {
    this.autonomyBeaconService.disconnectWallet()
    .then(() => { 
      window.location.href = '/' 
    }, (error) => {
      // TODO: process error
      console.error(error);
    });
  }

}
